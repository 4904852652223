define(['lodash', 'experiment', 'layout/util/layout', 'layout/specificComponents/imageLayout'], function (_, experiment, /** layout.layout */ layout, imageLayout) {
    'use strict';

    const isBolt = () => typeof window === 'object' && _.get(window, 'wixBiSession.renderType') === 'bolt';
    const isUsingWixImage = () => experiment.isOpen('bv_wixImagePhaseTwo') && isBolt();

    if (!isUsingWixImage()) {
        layout.registerCustomLayoutFunction('wysiwyg.common.components.imagebutton.viewer.ImageButton', imageLayout.layoutChildNodeImages);
    }
});
