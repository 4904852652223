define(['lodash', 'layout/util/layout'], function (_, layout) {
    'use strict';

    function measureWixAds(id, measureMap, nodesMap, structureInfo, {isMobileView}) {
        const MEASURES_TO_MODIFY = ['height', 'width', 'top', 'left'];
        const children = ['top'];
        const boundingClientRect = nodesMap.WIX_ADStop.getBoundingClientRect();

        measureMap.height.WIX_ADStop = boundingClientRect.height;

        _.forEach(children, function (child) {
            const childId = id + child;
            const currentChildRect = nodesMap[childId].getBoundingClientRect();
            measureMap.custom[childId] = currentChildRect;

            _.forEach(MEASURES_TO_MODIFY, function (key) {
                measureMap[key][childId] = Math.ceil(currentChildRect[key]);
            });
        });

        if (isMobileView()) {
            measureMap.top.WIX_ADStop = 0;
        }
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.WixAds', [['top'], ['bottom']]);
    layout.registerCustomMeasure('wysiwyg.viewer.components.WixAds', measureWixAds);
});
