define(['lodash', 'layout/util/layout', 'layout/util/spaceCalculationsRegardingWixAds'], function (_, layout, spaceCalculationsRegardingWixAds) {
    'use strict';

    function patchDialog(id, patchers, measureMap) {
        const dialogSkinPartId = `${id}dialog`;
        const dialogHeight = measureMap.height[dialogSkinPartId];
        const screenHeight = measureMap.height.screen;
        const firstUnoccupiedTopCoordinate = spaceCalculationsRegardingWixAds.getFirstUnoccupiedTopCoordinate(measureMap);
        const screenHeightWithoutWixAds = spaceCalculationsRegardingWixAds.getScreenHeightExcludingAds(measureMap);
        const dialogTopMargin = 20;

        patchers.css(id, {'height': `${screenHeight}px`});

        const halfOfRemainingHeight = (screenHeightWithoutWixAds - dialogHeight) / 2;

        patchers.css(dialogSkinPartId, {
            'top': `${Math.max(halfOfRemainingHeight, dialogTopMargin) + firstUnoccupiedTopCoordinate}px`
        });
    }

    function registerDialogToLayout(dialogCompType) {
        layout.registerRequestToMeasureChildren(dialogCompType, [['dialog']]);
        layout.registerPatcher(dialogCompType, patchDialog);
    }

    const dialogComponentsToLayout = [
        'wysiwyg.viewer.components.dialogs.EnterPasswordDialog',
        'wysiwyg.viewer.components.dialogs.NotificationDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.EmailVerificationDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.MemberLoginDialog',
        'wysiwyg.viewer.components.dialogs.CaptchaDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.NoPermissionsToPageDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.RequestPasswordResetDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.ResetPasswordDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.SentConfirmationEmailDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.SignUpDialog',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.WelcomeDialog'
    ];

    _.forEach(dialogComponentsToLayout, registerDialogToLayout);
});
