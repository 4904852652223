define([], function () {
    'use strict';

    function toNumber(measure) {
        const parsedMeasure = parseInt(measure, 10);
        return isNaN(parsedMeasure) ? 0 : parsedMeasure;
    }


    function getFirstUnoccupiedTopCoordinate(measureMap) {
        return toNumber(measureMap.height.WIX_ADStop) + toNumber(measureMap.top.WIX_ADStop);
    }

    function getScreenHeightExcludingAds(measureMap) {
        const totalScreenHeight = measureMap.height.screen;
        const topAdSpace = toNumber(measureMap.height.WIX_ADStop) + toNumber(measureMap.top.WIX_ADStop);
        return totalScreenHeight - topAdSpace;
    }


    return {
        getFirstUnoccupiedTopCoordinate,
        getScreenHeightExcludingAds
    };
});



