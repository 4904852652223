define(['zepto', 'lodash', 'experiment', 'warmupUtils'], function ($, _, experiment, warmupUtils) {
    'use strict';

    return {
        updateBodyNodeStyle(layoutAPI) {
            const $body = $('body');
            const $html = $('html');

            if (layoutAPI.isQaMode()) {
                $body.addClass('qa-mode');
            }

            if (!experiment.isOpen('sv_noSpecificCompLayout')) {
                $body.attr('data-js-loaded', true);
            }

            if (layoutAPI.isMobileView()) {
                // Fix overflow-x for some mobile devices
                $body.addClass('device-mobile-optimized');
                $html.addClass('device-mobile-optimized');
            } else if (layoutAPI.isMobileDevice() || layoutAPI.isTabletDevice() || warmupUtils.mobileUtils.isIpadIOS13()) {
                $body.addClass('device-mobile-non-optimized');
            } else {
                $body
                    .removeClass('device-mobile-optimized')
                    .removeClass('device-mobile-non-optimized');

                $html.removeClass('device-mobile-optimized');
            }

            if (layoutAPI.isMobileDevice()) {
                const browserSettings = _.assign({}, layoutAPI.os, layoutAPI.browser);
                const mobileClass = _(browserSettings)
                    .keys()
                    .filter(key => browserSettings[key] === true)
                    .map(value => `device-${value}`)
                    .join(' ');
                $html.addClass(mobileClass);
            }

            const currentOverflow = $body.css('overflow');
            const newOverflow = layoutAPI.renderFlags.allowSiteOverflow ? null : 'hidden';
            if (currentOverflow !== newOverflow) {
                $body.css('overflow', newOverflow);
            }
        }
    };
});
