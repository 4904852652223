define(['lodash', 'layout/util/layout'], function (_, layout) {
    'use strict';
    const compName = 'wysiwyg.viewer.components.RefComponent';

    function measureRefComponent(id, measureMap, nodesMap, structureInfo) {
        measureMap.shrinkableContainer[id] = true;

        const firstChildId = _.get(structureInfo, ['structure', 'components', 0, 'id']);

        if (firstChildId && nodesMap[firstChildId]) {
            const el = nodesMap[firstChildId];

            measureMap.height[id] = el.offsetHeight;
            measureMap.width[id] = el.offsetWidth;
        }
    }

    layout.registerMeasureChildrenFirst(compName, true);
    layout.registerRequestToMeasureDom(compName);
    layout.registerCustomMeasure(compName, measureRefComponent);
});
