define(['lodash', 'zepto', 'layout/util/layout'], function (_, $, /** layout.layout */ layout) {
    'use strict';

    function fixCollageGallery(domNode) {
        const id = domNode.getAttribute('id');
        const iframe = domNode.ownerDocument.getElementById(`${id}iframe`);
        const fitToScreenWidth = $(domNode).data('isScreenWidth');
        const parentWidth = domNode.offsetWidth;
        return [{
            node: iframe,
            type: 'css',
            changes: {
                width: fitToScreenWidth ? '100%' : parentWidth
            }
        }];
    }

    layout.registerCustomDomChangesFunction('tpa.viewer.components.Collage', fixCollageGallery);

    function updateDimensions(id, patchers) {
        patchers.css(id, {left: 0, width: '100%'});
        patchers.css(`${id}iframe`, {width: '100%'});
    }

    layout.registerRequestToMeasureChildren('tpa.viewer.components.Collage', [['iframe']]);
    layout.registerRequestToMeasureChildren('tpa.viewer.components.StripShowcase', [['iframe']]);
    layout.registerPatcher('tpa.viewer.components.StripShowcase', updateDimensions);
    layout.registerRequestToMeasureChildren('tpa.viewer.components.StripSlideshow', [['iframe']]);
    layout.registerPatcher('tpa.viewer.components.StripSlideshow', updateDimensions);
});
