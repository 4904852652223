define([
    'lodash',
    'zepto',
    'layout/util/layout',
    'warmupUtilsLib',
    'image-client-api',
    'layout/util/optimizedCssImageLayout'
], function (_, $, layout, warmupUtilsLib, imageClientApi, optimizedCssImageLayout) {
    'use strict';

    const balataConsts = warmupUtilsLib.mediaConsts.balataConsts;

    function isEmptyOrExternalUri(uri) {
        return !uri || warmupUtilsLib.urlUtils.isExternalUrl(uri);
    }

    function getImageTransformData(fittingType, alignType, imageData, targetDimensions, siteData, htmlTag) {
        const pixelAspectRatio = siteData.mobile.getDevicePixelRatio();
        const target = {
            width: targetDimensions.width,
            height: targetDimensions.height,
            alignment: alignType,
            htmlTag: htmlTag || 'bg',
            pixelAspectRatio
        };
        const src = {id: imageData.uri, width: imageData.width, height: imageData.height};
        const imageQualityFilters = _.defaults({quality: 85}, imageData.quality || {});
        return imageClientApi.getData(fittingType, src, target, imageQualityFilters, siteData.browser);
    }

    function measureLegacyBgImageStrip(id, measureMap, nodesMap/*, siteData, structureInfo*/) {
        const legacyBgImageId = `${id}bg`;
        const node = nodesMap[legacyBgImageId];
        if (!node) {
            return;
        }
        measureMap.custom[id] = {
            fittingType: $(node).data('fittingType'),
            alignType: $(node).data('alignType')
        };
        optimizedCssImageLayout.cacheCssImageMeasureData(measureMap.custom[id], node);
    }

    function measureBgImageBalata(balataNodeId, measureMap, nodesMap) {
        const imageCompNodeId = balataNodeId + balataConsts.MEDIA + balataConsts.IMAGE;
        const imageInfo = $(nodesMap[imageCompNodeId]).data('tiledImageInfo');
        imageInfo.imageData.displayMode = imageInfo.displayMode;
        measureMap.custom[imageCompNodeId] = {
            type: balataConsts.BG_IMAGE,
            imageInfo
        };
        optimizedCssImageLayout.cacheCssImageMeasureData(measureMap.custom[imageCompNodeId], nodesMap[imageCompNodeId]);
    }


    function patchBgImage(balataNodeId, imageCompNodeId, patchers, measureMap, siteData) {
        const {fittingType, alignType, imageData} = measureMap.custom[imageCompNodeId].imageInfo;
        const targetDimensions = {
            width: measureMap.width[imageCompNodeId],
            height: measureMap.height[imageCompNodeId]
        };
        const imageTransformData = getImageTransformData(fittingType, alignType, imageData, targetDimensions, siteData, 'bg');
        optimizedCssImageLayout.patchCssImage(measureMap.custom[imageCompNodeId], imageCompNodeId, patchers, imageTransformData.css.container, imageTransformData.uri, siteData);
    }

    function patchLegacyBgImageStrip(id, patchers, measureMap, structureInfo, siteData, parentDimensions) {
        const customMeasureData = measureMap.custom[id];
        const legacyBgImageId = `${id}bg`;
        if (customMeasureData) {
            const imageData = structureInfo.dataItem;
            if (!imageData || isEmptyOrExternalUri(imageData.uri)) {
                return;
            }
            const {fittingType, alignType} = customMeasureData;
            const imageTransformData = getImageTransformData(fittingType, alignType, imageData, parentDimensions, siteData);
            optimizedCssImageLayout.patchCssImage(customMeasureData, legacyBgImageId, patchers, imageTransformData.css.container, imageTransformData.uri, siteData);
        }
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.BgImageStrip', [['bg']]);

    return {
        measureLegacyBgImageStrip,
        measureBgImageBalata,
        patchBgImage,
        patchLegacyBgImageStrip
    };
})
;
